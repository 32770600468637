@import 'bootstrap/scss/bootstrap';
@import 'react-toastify/dist/ReactToastify.css';

// Import Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Open+Sans:ital,wght@0,400;0,600;1,400&display=swap');

// Variables
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Montserrat', sans-serif;
$font-size-base: 1.2rem; // 16px
$line-height-base: 1.5;

$bege: hsl(45, 15%, 95%);
$white: #FFFFFF;
$dark: #333333;
$mais-yellow: hsl(45, 89%, 52%);
$mais-orange: hsl(21, 100%, 50%);

// Typography
body {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    line-height: $line-height-base;
    color: $dark;
    background-color: $bege;
}

footer {
    background-color: $mais-orange;
    color: black;
    padding: 2rem 0;
    text-align: center;

    ul {
        list-style-type: none;
        padding: 0rem 2rem;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        border-right: hsla(0, 0%, 100%, 0.5) 1px solid;

        &:last-child {
            border-right: none;
        }


        li {
            display: inline-block;
            margin-right: 1rem;
        }
    }
}

nav {
    margin-top: auto;
    margin-bottom: auto;
    a {
        color: black;
        font-size: 1.5rem;
        font-weight: 700;
        margin-left: 1rem;
    }
}

a {
    color: hsla(0, 0%, 0%, 0.5);
    text-decoration: none;

    &:hover {
        color: black;
    }

    &.light {
        color: $bege;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headings-font-family;
    font-weight: 600;
    margin-bottom: 1rem;
}

h1,
.h1 {
    font-size: 3rem; // 48px
    font-weight: 700;
}

h2,
.h2 {
    font-size: 2.25rem; // 36px
}

h3,
.h3 {
    font-size: 1.75rem; // 28px
    font-weight: 500;
}

p {
    margin-bottom: 1.5rem;
}

.dark {
    color: darken($bege, 10%);
}

.bg-bege {
    background-color: transparentize($color: $bege, $amount: 0.5);
}

// Custom classes
.text-highlight {
    font-family: $font-family-sans-serif;
    font-style: italic;
    font-size: 1.125rem; // 18px
    color: $mais-orange;
}

.btn {
    font-family: $headings-font-family;
    font-weight: 600;
    font-size: 1rem;
    // text-transform: uppercase;
    border-radius: 50px;
    padding: 0.5rem 1.5rem;
    letter-spacing: .075rem;
}

.btn-primary {
    background-color: $mais-yellow;
    border-color: $mais-yellow;
    color: black;

    &:hover {
        background-color: darken($mais-yellow, 2%);
        border-color: darken($mais-yellow, 10%);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        color: #000;
    }
}

.btn-secondary {
    background-color: $mais-orange;
    border-color: $mais-orange;
    color: black;

    &:hover {
        background-color: darken($mais-orange, 2%);
        border-color: darken($mais-orange, 10%);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
        color: #000;
    }
}

.btn:disabled {
    background-color: hsl(0, 00%, 30%);
    border-color: hsl(0, 00%, 30%);
    color: black;
}

.navbar-nav {
    font-family: $headings-font-family;
    font-weight: 500;
    font-size: 0.875rem; // 14px
}

// Color utilities
.text-mais-orange {
    color: $mais-orange;
}

// Dark background adjustments
.bg-dark {
    color: $white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $white;
    }
}

// Responsive adjustments
@include media-breakpoint-down(md) {

    h1,
    .h1 {
        font-size: 2.5rem; // 40px
    }

    h2,
    .h2 {
        font-size: 2rem; // 32px
    }

    h3,
    .h3 {
        font-size: 1.5rem; // 24px
    }

    body {
        font-size: 0.9375rem; // 15px
    }
}

@include media-breakpoint-down(sm) {

    h1,
    .h1 {
        font-size: 2rem; // 32px
    }

    h2,
    .h2 {
        font-size: 1.75rem; // 28px
    }

    h3,
    .h3 {
        font-size: 1.375rem; // 22px
    }

    body {
        font-size: 0.875rem; // 14px
    }
}

// Accessibility
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}